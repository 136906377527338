import React from "react";
import LawyerHomePage from "../../pages/LawyerHomePage";

const LawyerHomePageBody = () => {
  return (
    <>
      <LawyerHomePage />
    </>
  );
};

export default LawyerHomePageBody;
